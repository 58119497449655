import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transaction-histories"
export default class extends Controller {
  static targets = ['order', 'requireReview']

  static values = {
    phase: String,
    isSellerPhase: Boolean
  }

  connect() {
    if (this.hasRequireReviewTarget && this.requireReviewTarget.value === 'true') {

      const goodButton = document.getElementById('good-button');

      if (goodButton) {
        // 2秒後にボタンを活性にする（モーダル自動表示よりも1秒後にする）
        setTimeout(function() {
          goodButton.classList.toggle("not_events");
        }, 2000);
      }

      setTimeout(() => {
        // いいねモーダルが存在してない場合に表示させる
        const modal_good = document.getElementById("modal_good");
        if (modal_good == null) {
          // 「いいね！を送る」ボタンをクリックしてモーダルを起動させる
          goodButton.click();
        }
      }, 1000);
    }
  }

  /**
   * チェックボックスの表示切替、検索パラメータ付きリクエストを送信する
   */
  togglecCustomerPhase(event) {
    event.target.parentNode.classList.toggle("is_current");
    event.target.parentNode.parentNode.classList.toggle("is_current");
    this.isCustomerPhaseValue = event.target.checked;
    this.requestOrders();
  }

  requestOrders() {
    // パラメータをセットして Turbo でリクエスト
    const url = `/transaction_histories?phase=${this.phaseValue}&is_customer_phase=${this.isCustomerPhaseValue}`;
    Turbo.visit(url, { frame: 'order_histories_frame' });
  }
}
