import { Controller } from "@hotwired/stimulus"

// 価格帯検索選択肢
const PRICE_LIST = [
  ['選択してください', ''],
  ['1,000円', 1000],
  ['2,000円', 2000],
  ['3,000円', 3000],
  ['4,000円', 4000],
  ['5,000円', 5000],
  ['6,000円', 6000],
  ['7,000円', 7000],
  ['8,000円', 8000],
  ['9,000円', 9000],
  ['10,000円', 10000],
  ['15,000円', 15000],
  ['20,000円', 20000],
  ['25,000円', 25000],
  ['30,000円', 30000]
];

const SORT_KEYS_COMMON = ["rank", "bookmark_count", "released_at"];
const SORT_KEYS_NORMAL = ["review_count", "review_comment_count", "price_asc", "price_desc"];
const SORT_KEYS_PRESENT = ["end_date"];

const NO_UPPER_OPTION = ['30,001円以上', ' '];

// Connects to data-controller="product-list-filter"
export default class extends Controller {

  connect() {
  }

  /**
   * 価格（上限）の設定
   */
  setUpperPriceSelectOptions() {
    const minPrice = document.getElementById("minPrice");
    const maxPrice = document.getElementById("maxPrice");

    // 下限金額に応じて選択肢を調整
    let priceList = PRICE_LIST.filter(option => option[1] > minPrice.value);

    // 先頭に挿入
    priceList.unshift(['選択してください', '']);

    // 末尾に挿入
    priceList.push(NO_UPPER_OPTION);

    maxPrice.innerHTML = priceList.map(option => `<option value="${option[1]}" ${option[1] == maxPrice.value ? 'selected' : ''}>${option[0]}</option>`).join('');
  }

  /**
   * サブカテゴリの設定
   */
  setSubCategorySelectOptions() {
    const categorySelect = document.querySelector("#categorySelect");
    const subCategorySelect = document.querySelector("#subCategorySelect");

    fetch(`/set_sub_categories/${categorySelect.value}`)
      .then(response => response.json())
      .then(data => {
        subCategorySelect.innerHTML = ""; // サブカテゴリの選択肢をクリア

        // "すべて"の選択肢を追加
        const allOption = document.createElement("option");
        allOption.value = "";
        allOption.textContent = "すべて";
        subCategorySelect.appendChild(allOption);

        data.forEach(subCategory => {
          const option = document.createElement("option");
          option.value = subCategory.id;
          option.textContent = subCategory.name;
          subCategorySelect.appendChild(option);
        });
      });
  }

  /**
   * 出品タイプの設定
   * 選択可能な検索条件を制御
   */
  toggleListingTypeSelectOptions() {
    const nowOnSaleBox = document.querySelector("#nowOnSale").parentElement;
    const shippingIncludedBox = document.querySelector("#shippingIncluded").parentElement;
    const presentOpenBox = document.querySelector("#presentOpen").parentElement;
    const listingType = document.querySelector("#listingTypeSelect").value;

    if (listingType == "normal") {
      nowOnSaleBox.classList.remove("d-none");
      shippingIncludedBox.classList.remove("d-none");
      presentOpenBox.classList.add("d-none");

    } else if (listingType == "present") {
      nowOnSaleBox.classList.add("d-none");
      shippingIncludedBox.classList.add("d-none");
      presentOpenBox.classList.remove("d-none");

    } else { // すべて
      nowOnSaleBox.classList.remove("d-none");
      shippingIncludedBox.classList.remove("d-none");
      presentOpenBox.classList.remove("d-none");

      document.querySelector("#nowOnSale").checked = false;
      document.querySelector("#shippingIncluded").checked = false;
      document.querySelector("#presentOpen").checked = false;
    }
  }

  /**
   * 販売中の商品のみ表示のチェックON / OFF切り替え
   * 選択可能な検索条件を制御
   */
  toggleNormalTypeConditionCheck() {
    const checkedNowOnSale = document.querySelector("#nowOnSale").checked;
    const checkedShippingIncluded = document.querySelector("#shippingIncluded").checked;
    if (checkedNowOnSale || checkedShippingIncluded) {
      // プレゼント出品のチェックボックスを非表示
      document.querySelector("#presentOpen").parentElement.classList.add("d-none");
      // 通常出品を選択
      document.querySelector("#listingTypeSelect").selectedIndex = 1;
    }
  }

  /**
   * 販売中の商品のみ表示のチェックON / OFF切り替え
   * 選択可能な検索条件を制御
   */
  togglePresentTypeConditionCheck() {
    if (document.querySelector("#presentOpen").checked) {
      // 通常出品のチェックボックスを非表示
      document.querySelector("#nowOnSale").parentElement.classList.add("d-none");
      document.querySelector("#shippingIncluded").parentElement.classList.add("d-none");
      // プレゼント出品を選択
      document.querySelector("#listingTypeSelect").selectedIndex = 2;
    }
  }

  /**
   * 絞り込み条件リセット
   */
  reset() {
    // 価格(下限)
    const minPrice = document.getElementById("minPrice");
    minPrice.innerHTML = PRICE_LIST.map(option => `<option value="${option[1]}" >${option[0]}</option>`);

    // 価格(上限)
    const maxPrice = document.getElementById("maxPrice");
    this.setUpperPriceSelectOptions();
    let maxPriceList = [
      ['選択してください', '']
    ];
    maxPrice.innerHTML = maxPriceList.map(option => `<option value="${option[1]}" >${option[0]}</option>`);

    // カテゴリ
    document.getElementById('categorySelect').selectedIndex = 0;

    // サブカテゴリ
    document.getElementById('subCategorySelect').selectedIndex = 0;

    // 出品者の会員区分（全てチェックONが初期値）
    let checkboxList = document.querySelectorAll('.memberStatus');
    for (let i = 0; i < checkboxList.length; i++) {
      if (checkboxList.hasOwnProperty(i)) {
        checkboxList[i].checked = true;
        checkboxList[i].parentNode.classList.add("is_current");
        checkboxList[i].parentNode.parentNode.classList.add("is_current");
      }
    }

    // 出品タイプ
    document.getElementById('listingTypeSelect').selectedIndex = 0;

    // 販売中の商品のみ表示
    document.getElementById("nowOnSale").checked = false;

    // 送料無料の商品のみ表示
    document.getElementById("shippingIncluded").checked = false;

    // 送料無料の商品のみ表示
    document.getElementById("presentOpen").checked = false;

    this.toggleListingTypeSelectOptions();
  }

  /**
   * 絞り込み条件での商品検索
   * SP用検索ボックスに検索条件を入力した場合は、event.params.spMode に true が入る
   * カテゴリのリンククリック時は event.params.categoryId に選択したカテゴリのIDが入る
   * event.detail.changeSort は並び替えセレクトの変更時に CustomEvent からセットされる
   */
  searchProducts(event) {
    // IME確定時のEnterを無視
    if (event.keyCode == 229) return false;

    this.setSearchParams(event.params.spMode, event.params.categoryId, event.detail.changeSort);
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件をhidden項目に保持
   * @param[Boolean] spMode true の場合はSP用の処理を行う
   * @param[Integer] categoryId 「カテゴリで絞り込む」リンクで選択されたカテゴリID
   * @param[Boolean] changeSort true の場合は並び替え条件が変更されたことを示す
   */
  setSearchParams(spMode, categoryId, changeSort = false) {
    const keyword = document.getElementById("keyword");

    // SP用キーワードの取得、検索履歴の登録
    if (spMode) {
      const spKeywordValue = document.getElementById("serviceSearchInput_sp").value;
      if (spKeywordValue.trim() != "") {
        keyword.value = spKeywordValue;
        this.saveKeyword(spKeywordValue);
      }
    }

    // カテゴリ
    const hiddenCategoryId = document.getElementById("hiddenCategoryId");

    // カテゴリのリンクをクリック時は categoryId が渡ってくる
    if (categoryId) {
      hiddenCategoryId.value = categoryId;

    // 絞り込みモーダルのカテゴリセレクト
    } else {
      const categorySelect = document.getElementById("categorySelect");
      const subCategorySelect = document.getElementById("subCategorySelect");
      if (subCategorySelect.value != ""){
        hiddenCategoryId.value = subCategorySelect.value;
      }else{
        hiddenCategoryId.value = categorySelect.value;
      }
    }

    // 価格(下限)
    const hiddenMinPrice = document.getElementById("hiddenMinPrice");
    const minPrice = document.getElementById("minPrice");
    hiddenMinPrice.value = minPrice.value;

    // 価格(上限)
    const hiddenMaxPrice = document.getElementById("hiddenMaxPrice");
    const maxPrice = document.getElementById("maxPrice");
    hiddenMaxPrice.value = maxPrice.value;

    // 出品者の会員区分
    let memberStatusArray = [];
    const checkboxList = document.querySelectorAll('.memberStatus');
    for (let i = 0; i < checkboxList.length; i++) {
      // checkボックスのON判定を is_current クラスの有無で判定
      if (checkboxList[i].parentNode.classList.contains('is_current')) {
        memberStatusArray.push(checkboxList[i].value);
      }
    }
    const hiddenMemberStatus = document.getElementById("hiddenMemberStatus");
    hiddenMemberStatus.value = memberStatusArray;

    // 出品者
    const hiddenSellerUid = document.getElementById("hiddenSellerUid");

    // 出品タイプ
    const hiddenListingType = document.getElementById("hiddenListingType");
    hiddenListingType.value = document.getElementById("listingTypeSelect").value;
    if (changeSort) {
      const sortType = this.getSortType();
      switch (sortType) {
        case "normal":
          hiddenListingType.value = "normal";
          break;
        case "present":
          hiddenListingType.value = "present";
          break;
        default:
          break;
      }
    }

    // 出品タイプに「すべて」以外を指定した場合、選択されなかったタイプの不要な条件をクリアする
    if (hiddenListingType.value == "present") {
      hiddenShippingIncluded.value = "";
      hiddenNowOnSale.value = "";
    } else {
      // 送料無料
      const hiddenShippingIncluded = document.getElementById("hiddenShippingIncluded");
      const shippingIncluded = document.getElementById("shippingIncluded");
      if (shippingIncluded.checked){
        hiddenShippingIncluded.value = "1";
      } else {
        hiddenShippingIncluded.value = "0";
      }

      // 販売中
      const hiddenNowOnSale = document.getElementById("hiddenNowOnSale");
      // 絞り込みモーダル内のチェック状態
      const nowOnSale = document.getElementById("nowOnSale");
      // 絞り込みモーダル外のチェック状態
      const nowOnSale02 = document.getElementById("nowOnSale02");
      // 絞り込みモーダルを表示している場合のみ
      if (nowOnSale02 != null) {
        if (nowOnSale.checked){
          // 絞り込みモーダル内の販売中チェックがある場合にチェックがされたとみなす
          hiddenNowOnSale.value = "1";
        } else {
          hiddenNowOnSale.value = "";
          nowOnSale02.checked = false;
        }
      }
    }
    if (hiddenListingType.value == "normal") {
      hiddenPresentOpen.value = "";
    } else {
      // プレゼント募集中
      const hiddenPresentOpen = document.getElementById("hiddenPresentOpen");
      const presentOpen = document.getElementById("presentOpen");
      const presentOpen02 = document.getElementById("presentOpen02");
      if (presentOpen02 != null) {
        if (presentOpen.checked){
          hiddenPresentOpen.value = "1";
        } else {
          hiddenPresentOpen.value = "";
          presentOpen02.checked = false;
        }
      }
    }
  }

  // 会員区分のチェックボックスON / OFF切り替え
  toggleCheckboxStyle(event) {
    event.target.parentNode.classList.toggle("is_current");
    event.target.parentNode.parentNode.classList.toggle("is_current");
  }

  // 検索画面表示時、複数箇所の販売中チェックボックスのON / OFF切り替えを同期させる
  toggleNowOnSale(event) {
    // 同期
    const nowOnSale = document.getElementById("nowOnSale");
    const nowOnSale02 = document.getElementById("nowOnSale02");
    nowOnSale.checked = nowOnSale02.checked;

    // 「販売中の...」、「送料無料の...」のいずれかがONの場合は出品タイプを「通常出品」に制限する
    const shippingIncluded = document.getElementById("shippingIncluded");
    const checked = nowOnSale.checked || shippingIncluded.checked;
    this.toggleListingTypeSelect(checked, "normal");
    this.searchProducts(event);
  }

  // 検索画面表示時、複数箇所の送料無料チェックボックスのON / OFF切り替えを同期させる
  toggleShippingIncluded(event) {
    // 同期
    const shippingIncluded = document.getElementById("shippingIncluded");
    const shippingIncluded02 = document.getElementById("shippingIncluded02");
    shippingIncluded.checked = shippingIncluded02.checked;

    // 「販売中の...」、「送料無料の...」のいずれかがONの場合は出品タイプを「通常出品」に制限する
    const nowOnSale = document.getElementById("nowOnSale");
    const checked = nowOnSale.checked || shippingIncluded.checked;
    this.toggleListingTypeSelect(checked, "normal");
    this.searchProducts(event);
  }

  // チェックボックスのON / OFF切り替えを同期させる
  togglePresentOpen(event) {
    // 同期
    const presentOpen = document.getElementById("presentOpen");
    const presentOpen02 = document.getElementById("presentOpen02");
    presentOpen.checked = presentOpen02.checked;

    // 「募集中のプレゼント...」がONの場合は出品タイプを「プレゼント出品」に制限する
    this.toggleListingTypeSelect(presentOpen.checked, "present");
    this.searchProducts(event);
  }

  toggleListingTypeSelect(checked, type) {
    const listingTypeSelect = document.getElementById("listingTypeSelect");
    if (checked) {
      listingTypeSelect.value = type;
    } else {
      listingTypeSelect.value = "";
    }
  }

  /**
   * 検索キーワードをローカルストレージに保存
   */
  saveKeyword(keyword) {
    const maxLength = 5; // 最大保存件数
    const serializedArray = localStorage.getItem('keywordArray');
    let keywordArray = JSON.parse(serializedArray);

    if (!keywordArray) keywordArray = [];

    // すでに保存済のワードであれば先頭に移動させるために一旦削除
    const keyIndex = keywordArray.indexOf(keyword);
    if (keyIndex > -1) keywordArray.splice(keyIndex, 1);

    // 先頭に追加
    keywordArray.unshift(keyword);

    // Max５件超過分は削除
    if (keywordArray.length > maxLength) keywordArray.splice(maxLength);

    // 保存
    const newSerializedArray = JSON.stringify(keywordArray);
    localStorage.setItem('keywordArray', newSerializedArray);
  }

  /**
   * 絞り込み条件リセット（全て）
   */
  resetSearchFilters(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenMinPrice.value = "";
    hiddenMaxPrice.value = "";
    hiddenCategoryId.value = "";
    hiddenNowOnSale.value = "";
    hiddenShippingIncluded.value = "";
    keyword.value = "";
    hiddenSellerUid.value = "";
    hiddenListingType.value = "";
    hiddenPresentOpen.value = "";
    this.resetSortKeys();
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（価格）
   */
  resetPriceFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenMinPrice.value = "";
    hiddenMaxPrice.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（カテゴリ）
   */
  resetCategoryFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenCategoryId.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（サブカテゴリ）
   */
  resetSubCategoryFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.subCategoryId);
    // 親カテゴリIDを設定
    hiddenCategoryId.value = categorySelect.value;
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（販売中）
   */
  resetNowOnSaleFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenNowOnSale.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }
  /**
   * 絞り込み条件リセット（送料無料）
   */
  resetShippingIncludedFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenShippingIncluded.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（キーワード）
   */
  resetKeywordFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    keyword.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（出品者）
   */
  resetSellerFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenSellerUid.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（出品タイプ: 通常商品）
   */
  resetListingTypeNormal(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenListingType.value = "";

    // 通常出品専用の条件をクリア
    hiddenMinPrice.value = "";
    hiddenMaxPrice.value = "";
    hiddenNowOnSale.value = "";
    hiddenShippingIncluded.value = "";

    this.resetSortKeys();
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（出品タイプ: 通常商品）
   */
  resetListingTypePresent(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenListingType.value = "";

    // プレゼント出品専用の条件をクリア
    hiddenPresentOpen.value = "";

    this.resetSortKeys();
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（募集中）
   */
  resetPresentOpenFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenPresentOpen.value = "";
    this.resetSortKeys();
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 各出品タイプ専用のソートを指定し、かつ同タイプ用の検索条件が設定されていない場合にデフォルトのソートに変更
   */
  resetSortKeys() {
    const sortType = this.getSortType();

    if (sortType == "common") return;
    if (sortType == "normal" && this.isNormalTypeSearch()) return;
    if (sortType == "present" && this.isPresentTypeSearch()) return;

    // 条件をクリアした際に検索結果が0件にならないようソートをデフォルトに戻す
    document.getElementById("hiddenSort").value = SORT_KEYS_COMMON[0];
    document.getElementById("sortSelect").value = SORT_KEYS_COMMON[0];
  }

  /**
   * 選択されたソート条件が属している出品タイプを返す
   */
  getSortType() {
    const sortKey = document.getElementById("hiddenSort").value;
    if (SORT_KEYS_COMMON.includes(sortKey)) return "common";
    if (SORT_KEYS_NORMAL.includes(sortKey)) return "normal";
    if (SORT_KEYS_PRESENT.includes(sortKey)) return "present";
  }

  /**
   * 通常出品用の検索条件が１つでも設定されていれば true を返す
   */
  isNormalTypeSearch() {
    return hiddenListingType.value == "normal"
      || hiddenMinPrice.value != ""
      || hiddenNowOnSale.value == "1"
      || hiddenShippingIncluded.value == "1"
  }

  /**
   * プレゼント出品用の検索条件が１つでも設定されていれば true を返す
   */
  isPresentTypeSearch() {
    return hiddenListingType.value == "present"
      || hiddenPresentOpen.value == "1"
  }
}
