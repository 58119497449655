import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import imageCompression from "browser-image-compression";
import Sortable from "sortablejs";

// Connects to data-controller="image-upload-form"
export default class extends Controller {
  static targets = ['fileSelect'];

  connect() {
    this.onFileRemove()
    this.enableDragAndDrop();
  }

  // 初期状態で画像プレビューがある場合の削除ボタンの処理
  onFileRemove() {
    $('.fileRemoveBtn').each(function(){
      $(this).on("click", function () {
        $(this).parent().parent().prev().removeClass("is_max");
        $(this).parent().remove();
      });
    })
  }

  // 商品写真の並び替え処理
  enableDragAndDrop() {
    const selectedPictures = document.querySelectorAll("ul.selected");
    const elem = this.fileSelectTarget;
    const fileSelectBtn = elem.parentNode;

    selectedPictures.forEach((selectedPicture) => {
      // すでにSortableが適用されている場合は処理をスキップ
      if (Sortable.get(selectedPicture) !== undefined) return;

      Sortable.create(selectedPicture, {
        filter: ".fileRemoveBtn",
        onFilter: function (evt) {
          // イベントのアイテム（削除ボタンの親のli要素）を取得
          let itemToRemove = evt.item.closest("li");
          // アイテムが存在する場合はDOMから削除
          itemToRemove && itemToRemove.parentNode.removeChild(itemToRemove);
          // 写真を選択ボタンを活性化
          fileSelectBtn.classList.remove("is_max");
        },
      });
    });
  }

  fileLimit(event) {
    const elem = this.fileSelectTarget
    const limit = Number(elem.dataset.limit) // アップロード可能なファイルの上限数
    const dataSizeLimit = Number(elem.dataset.threshold) // アップロード可能なファイルの最大容量
    const type = elem.dataset.type // アップロードする画像の種類 'product', 'license', or 'QR'
    const selectedPicture = elem.parentNode.nextElementSibling; // プレビュー表示エリア
    const fileSelectBtn = elem.parentNode; // 「写真を選択」ボタン
    const selectedNum = selectedPicture.children.length; // すでに選択済みで表示されている枚数
    let filesNum = elem.files.length; // 新しく選択した画像の枚数

    let loadStartCount = 0;  // ファイル読み込み開始カウンタ
    let loadEndCount = 0;    // ファイル読み込み完了カウンタ

    for (let i = 0; i < filesNum; i++) {
      // ファイル枚数超過時の警告
      if (limit < filesNum + selectedNum) {
        Modal.getOrCreateInstance(document.getElementById('alertModal_imageNumber')).show();
        filesNum = 0;
        fileSelectBtn.classList.remove("is_max");
        return;
      } else if (limit === filesNum + selectedNum) {
        fileSelectBtn.classList.add("is_max");
      } else {
        fileSelectBtn.classList.remove("is_max");
      }

      // ファイルが指定サイズ以上だと警告とアイテム削除
      if (elem.files[i].size > dataSizeLimit) {
        //エラーメッセージを出し分けるための分岐
        if(type == 'license'){
          Modal.getOrCreateInstance(document.getElementById('alertModal_imageSize_license')).show()
        } else {
          Modal.getOrCreateInstance(document.getElementById('alertModal_imageSize')).show()
        }
        fileSelectBtn.classList.remove("is_max");
        return false;
      }

      // ファイルの圧縮・読み込み
      const fileReader = new FileReader();

      // ファイル読み込み開始前に is_uploading クラスを追加
      if (loadStartCount === 0) {
        fileSelectBtn.classList.add("is_uploading");
      }
      loadStartCount++;  // ファイル読み込み開始カウンタを増やす

      this.compressFile(elem.files[i], fileReader)

      // hiddenタグを生成
      const hiddenIdTag = this.createHiddenTag(`${event.params.prefix}upload_images[id][]`, null)
      const hiddenImageTag = this.createHiddenTag(`${event.params.prefix}upload_images[image][]`, null)
      const hiddenFileNameImageTag = this.createHiddenTag(`${event.params.prefix}upload_images[filename][]`, elem.files[i].name)

      fileReader.onload = function () {
        loadEndCount++;  // ファイル読み込み完了カウンタを増やす

        if (limit >= filesNum) {
          // ファイル読み取りが完了後の処理
          let imgTag = `<li draggable="true"><img src='${fileReader.result}'><span class="fileRemoveBtn">削除</span></li>`;

          selectedPicture.insertAdjacentHTML("beforeend", imgTag);

          // 直前に追加した削除ボタンを取得
          let lastRemoveBtn = null;
          if (limit == 1) {
            lastRemoveBtn = $('.fileRemoveBtn');
          } else {
            lastRemoveBtn = $('.fileRemoveBtn:last');
          }

          // 画像送信用 input を作成してプレビュー画像と削除ボタンの間に挿入
          hiddenImageTag.value = fileReader.result;
          lastRemoveBtn.before(hiddenIdTag);
          lastRemoveBtn.before(hiddenImageTag);
          lastRemoveBtn.before(hiddenFileNameImageTag);

          // 削除ボタンで該当の写真削除
          lastRemoveBtn.on("click", function () {
            $(this).parent().remove();
            fileSelectBtn.classList.remove("is_max");
          });
        }

        // ファイル読み込み完了後に is_uploading クラスを削除
        if (loadStartCount === loadEndCount) {
          fileSelectBtn.classList.remove("is_uploading");

        }
      };
    }
    // 連続して同じファイルを選択できるように選択済みのものをクリア
    event.target.value = '';
  }

  createHiddenTag(name, value) {
    const hiddenTag = document.createElement('input');
    hiddenTag.type = 'hidden';
    hiddenTag.name = name;
    hiddenTag.value = value;
    return hiddenTag
  }

  // ファイルの読み込み
  async compressFile(file, fileReader) {
    const compressedFile = await imageCompression(file, { maxSizeMB: 1 });
    fileReader.readAsDataURL(compressedFile);
  }
}
