import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="seller-presents-entry"
export default class extends Controller {
  static targets = ["message", "cancelMessage"];
  static values = {
    name: String,     // 応募者の名前
    icon: String,     // 応募者のアイコン（URL）
    rankIcon: String, // 応募者ランクアイコンのCSSクラス名
  }

  connect() {
    this.omitMessages();
  }

  // メッセージを表示する領域の高さが3行を超えた場合、省略表示にする
  omitMessages() {
    if (!this.hasMessageTarget) return;

    const messageHeight = this.messageTarget.offsetHeight;
    if (messageHeight > 84){ // 高さ84pxを3行とする
      this.messageTarget.parentElement.classList.add('is_over');
    }
  }

  // 応募メッセージモーダルを表示
  openEntryMessageModal(event) {
    // 押されたリンクの Params から応募者の情報を取得し、モーダルにセットする
    document.getElementById('entry_user_name').textContent = this.nameValue;
    document.getElementById('entry_user_icon').src = this.iconValue;
    document.getElementById('entry_user_rank_icon').classList.add(this.rankIconValue);
    document.getElementById('entry_user_message').innerHTML = this.messageTarget.innerHTML;
    Modal.getOrCreateInstance(document.getElementById('entryMessageModal')).show();
  }

  // キャンセル理由モーダルを表示
  openCancelMessageModal(event) {
    document.getElementById('cancel_entry_user_name').textContent = this.nameValue;
    document.getElementById('cancel_entry_user_icon').src = this.iconValue;
    document.getElementById('cancel_entry_user_rank_icon').classList.add(this.rankIconValue);
    document.getElementById('cancel_message').innerHTML = this.cancelMessageTarget.innerHTML;
    Modal.getOrCreateInstance(document.getElementById('cancelMessageModal')).show();
  }
}
