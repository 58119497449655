import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle";

// Connects to data-controller="present"
export default class extends Controller {
  connect() {
    this.productImgModal();
  }

  productImgModal() {
    // 商品写真モーダル
    $(function () {
      let swiper
      let swiperThumbnail

      if (window.matchMedia('(max-width: 768px)').matches) {
        const productImgSpSlide = new Swiper(".productImgSpSlide", {
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      };

      // モーダルを開く動作
      $('.js-productImgModalOpen').click(function(){

        swiperThumbnail = new Swiper(".productImgModal .swiperThumbnail", {
          // サムネイルの枚数
          slidesPerView: 10,
        });

        swiper = new Swiper('.productImgModal .swiperMain', {
          loop: true,
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: swiperThumbnail,
          },
        });

        let index
        const index_pc = $(this).data('index'); // クリックされた画像のインデックスを取得
        const index_sp = $(".swiper-slide-active").data('swiper-slide-index'); // （SP用）表示している画像のインデックスを取得
        if (typeof index_pc !== 'undefined') {
          index = index_pc
        }else{
          index = index_sp
        }
        swiper.slideTo(index, 0, false); // 指定されたスライド（画像）へ移動

        $('.js-productImgModal').fadeIn();
        $('body').css('overflow-y', 'hidden');
      });

      $('.js-productImgModalClose, .js-productImgModal').click(function () {
        $('.js-productImgModal').css('opacity', 0);

        // Swiperのインスタンスを破棄
        if (swiper) {
          swiper.destroy(true, true);
          swiper = null;
        }
        if (swiperThumbnail) {
          swiperThumbnail.destroy(true, true);
          swiperThumbnail = null;
        }
        $('.js-productImgModal').fadeOut(function() {
          $('.js-productImgModal').css('opacity', 1);
        });
        $('body').css('overflow-y','auto');
      });

      $('.js-productImgModalClickArea').on('click', function (e) {
        e.stopPropagation();
      });

    });
  }
}
