// 出品者メニュー プレゼント出品管理画面用

import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="seller-presents"
export default class extends Controller {
  static values = {
    status: String
  }

  /**
   * 並び替えを選択するとフォームを送信する
   * event.target.value: セレクトボックスで選択された値
   * this.filterValue: data-seller-presents-filter-value="open" フィルターの値
   */
  selectSortOrder(event) {
    // パラメータをセットして Turbo でリクエストする
    const url = `/seller/presents/items?sort_key=${event.target.value}&status=${this.statusValue}`;
    Turbo.visit(url, { frame: 'product_manage_frame' })
  }

  // 「プレゼント出品を完了する」ボタンの活性・非活性を切り替える
  togglePresentComplete(event) {
    const BtnComplete = document.querySelector('.js_btn_complete');
    if (event.target.checked) {
      BtnComplete.classList.remove('is_disabled');
    } else {
      BtnComplete.classList.add('is_disabled');
    }
  }

  // 「当選通知を送る」モーダルを表示
  openSendWinningModal(event) {
    // 押されたボタンの Params から応募IDを取得し、モーダルのフォームにセットする
    document.getElementById('winning_entry_id').value = event.params.entryId;
    Modal.getOrCreateInstance(document.getElementById('sendWinningModal')).show();
  }
}
